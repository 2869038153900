<template>
  <a @click="onClick">이용약관</a>
  <kendo-window :ref="'kendoWindow'" title="회원이용약관" width="1000px" height="800px" :modal="true" style="display: none">
    <div class="term_text">
      <h4>제1장 총 칙</h4>
      <h5>제1조(목적)</h5>
      <p>
        이 약관은 코잔(주)(전자거래 사업자, 이하 “회사”라 합니다)가 운영하는 “MagicLine포털”에서 제공하는 인터넷 관련 서비스(이하 &quot;서비스&quot;라 한합니다)를 이용함에 있어 “회사”와 이용자의 권리•의무 및 책임사항을 규정함을 목적으로 합니다.
      </p>
      <h5>제2조(정의)</h5>
      <p>
        ① “MagicLine”이란 디지털, 3D 프린팅 기술 등을 이용한 치과 기공물 및 보철물 등을 제작, 시술 등 “회사”가 제공하는 서비스를 포괄하는 브랜드 또는 상품명을 의미하고, MagicAlign, Magic Positioner, Magic Retainer, Magic Space의 브랜드 또는 상품(구체적인 상품 또는 브랜드는 시장상황에 따라 추가•변경•삭제될 수 있습니다)을 포함합니다.
        <br />
        ② “MagicLine 포털”은
        <br />
        i) 치과 기공물 또는 보철물의 제작 의뢰,
        <br />
        ii) 이와 관련한 원•부자재의 주문 등을 위하여 “회사”가 운영하는 인터넷 홈페이지(http://www.magic-line.co.kr)를 의미합니다.
        <br />
        ③&quot;이용자&quot;란 “MagicLine 포털”에 접속하여 이 약관에 따라 “MagicLine 포털”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
        <br />
        “회원”이라 함은 “MagicLine 포털”에 개인정보를 제공하여 회원등록을 한 자로서, “MagicLINE 포털”의 정보를 지속적으로 제공받으며, “Magic Line 포털”이 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
        <br />
        ④ '비회원'이라 함은 회원에 가입하지 않고 &quot;MagicLine 포털&quot;이 제공하는 서비스를 이용하는 자를 말합니다.
      </p>
      <h5>제3조(약관등의 명시와 설명 및 개정)</h5>
      <p>
        ① “MagicLine 포털”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호•모사전송번호•전자우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 “MagicLine 포털”의 초기 서비스화면(전면)에 게시합니다.
        다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
        <br />
        ② “MagicLine 포털&quot;은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회•배송책임•환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 확인을 구하여야 합니다.
        <br />
        ③ “MagicLine 포털”은 전자상거래등에서의소비자보호에관한법률, 약관의규제에관한법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진등에관한법률, 방문판매등에관한법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
        <br />
        ④ “MagicLine 포털”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 “MagicLine 포털”몰의 초기화면에 그 적용일자 7일이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 “MagicLine 포털”은 개정전 내용과 개정후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.<br />
        ⑤ &quot;MagicLine 포털”이 제4항에 따라 변경된 약관을 공지하거나 통지하면서, 이용자에게 약관 변경 적용일까지 거부의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주한다는 내용을 공지하거나 통지하였음에도 회원이 명시적으로 약관 변경에 대한 거부 의사를 표시하지 않는다면 회원이 변경된 약관에 동의한 것으로 간주합니다.
        <br />
        약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다.
        다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제4항에 의한 개정약관의 공지기간내에 “MagicLine 포털”에 송신하여 “MagicLine 포털”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
      </p>
      <p>
        ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자보호지침 및 관계법령 또는 상관례에 따릅니다.<br />
      </p>
      <h5>제4조(서비스의 제공 및 변경)</h5>
      <p>① “MagicLine 포털”은 다음과 같은 업무를 수행합니다.</p>
      <p>
        가. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
        <br />
        나. 구매계약이 체결된 재화 또는 용역의 배송
        <br />
        다. 기타 “회사”가이 정하는 업무
      </p>
      ② “회사”는 “MagicLine 포털”내의은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다.
      이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
      <br />
      ③ “회사”가 “MagicLine 포털”이에서 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
      <br />
      ④ 전항의 경우 “회사”는은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “회사”가이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
      <br />
      <p></p>
      <h5>제5조(서비스의 중단)</h5>
      <p>
        ① “MagicLine 포털”은 다음과 각 호에 해당하면 “MagicLine 포털” 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
        <br />
        가. 컴퓨터 등 정보통신설비의 보수점검•교체 및 고장
        <br />
        나. 정전, 통신의 두절, 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
        <br />
        다. “MagicLine 포털” 서비스의 변경 및 중단과 같은 특수한 사정으로 본 서비스를 유지할 수 없는 경
         <br />
        ② 전 항에 따라 “MagicLine 포털” 서비스를 중단할 때에는 회사는 제8조에서 정한 방법으로 회원에게 통지합니다.
        다만, 회사가 통제할 수 없는 사유로 중단하게 되어 미리 통지할 수 없었을 때에는 그러하지 아니합니다.
        <br />
        ③ 회사의 고의 또는 중과실이 없는 한 본 조에 따른 “MagicLine 포털”서비스의 변경, 중지로 발생한 문제에 대해 책임을 지지 않습니다.
        사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “회사”는 제8조에 정한 방법으로 이용자에게 통지하고 당초 “Magic Line 포털”에서 제시한 조건에 따라 소비자에게 보상합니다.
        다만, “MagicLine 포털”에 보상기준 등을 고지되지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “MagicLine 포털”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.
      </p>
      <h4>제2장 회원가입 계약</h4>
      <h5>제6조(회원가입)</h5>
      <p>
        ① 이용자는 “회사”가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
        <br />
        ② “회사”는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.
        <br />
        가. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “회사”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
        <br />
        나. 등록 내용에 허위, 기재누락, 오기가 있는 경우
        <br />
        다. 기타 회원으로 등록하는 것이 “MagicLine 포털”의 기술상 현저히 지장이 있다고 판단되는 경우
        <br />
        ③ 회원가입계약의 성립시기는 “회사”의 승낙이 회원에게 도달한 시점으로 합니다.
        <br />
        ④ 회원은 제17조제1항에 의한 등록사항에 변경이 있는 경우, 즉시 전자우편 기타 방법으로 “회사”에 대하여 그 변경사항을 알려야 
      </p>
      <h5>제7조(회원 탈퇴 및 자격 상실 등)</h5>
      <p>
        ① 회원은 “회사”에 언제든지 “MagicLine 포털”의 탈퇴를 요청할 수 있으며 “회사”는 즉시 회원탈퇴를 처리합니다.
        <br />
        ② 회원이 다음 각호의 사유에 해당하는 경우, “회사”는 회원자격을 제한 및 정지시킬 수 있습니다.
        <br />
        가. 가입 신청시에 허위 내용을 등록한 경우
        <br />
        나. “MagicLine 포털”을 이용하여 구입한 재화 등의 대금, 기타 “MagicLine 포털”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
        <br />
        다. 다른 사람의 “MagicLine 포털” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
        <br />
        라. “MagicLine 포털”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
        <br />
        ③ “회사”가 회원 자격을 제한•정지 시킨후, 동일한 행위가 2회이상 반복되거나 30일이내에 그 사유가 시정되지 아니하는 경우 “회사”는 회원자격을 상실시킬 수 있습니다.
        <br />
        ④ “회사”가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
      </p>
      <h5>제8조(회원에 대한 통지)</h5>
      <p>
        ① “MagicLine 포털”이 회원에 대한 통지를 하는 경우, 회원이 “MagicLine 포털”과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.
        <br />
        ② “MagicLine 포털”은 불특정다수 회원에 대한 통지의 경우 1주일이상 “MagicLine 포털” 게시판에 게시함으로서 개별 통지갈음할 수 있습니다.
        다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
      </p>
      <h4>제3장 구매계약</h4>
      <h5>제9조(구매신청)</h5>
      <p>
        “MagicLine 포털”이용자는 “MagicLine 포털”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “MagicLine 포털”은 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
        단, 가입회원인 경우 “나”호 내지 “라”호의 적용을 제외할 수 있습니다.
        <br />
        가. 재화 등의 검색 및 선택
        <br />
        나. 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
        <br />
        다. 약관내용, 청약철회권이 제한되는 서비스, 배송료•설치비 등의 비용부담과 관련한 내용에 대한 확인
        <br />
        라. 재화등의 구매신청 및 이에 관한 확인 또는 “회사”의 확인에 대한 동의
        <br />
        마. 결제방법의 선택
      </p>
      <h5>제10조 (계약의 성립)</h5>
      <p>
        ① “회사”는 제9조와 같은 구매신청에 대하여 다음 각호에 해당하면 승낙하지 않을 수 있습니다.
        다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
        <br />
        가. 신청 내용에 허위, 기재누락, 오기가 있는 경우
        <br />
        나. 회원가입기준에 준하지 않는 자가 의약품 및 재료를 구매하는 경우
        <br />
        다. 기타 구매신청에 승낙하는 것이 “MagicLine 포털” 기술상 현저히 지장이 있다고 판단 
        <br />
        ② “회사”의 승낙이 제12조 제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
        <br />
        ③ “회사”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소등에 관한 정보등을 포함하여야 합니다.
      </p>
      <h4>제4장 대금결제</h4>
      <h5>제11조(지급방법)</h5>
      <p>
        “MagicLine 포털”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각호의 방법중 가용한 방법으로 할 수 있습니다.
        <br />
        가. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
        <br />
        나. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
        <br />
        다. 온라인 무통장입금
      </p>
      <h5>제12조(수신확인통지•구매신청 변경 및 취소)</h5>
      <p>
        ① “회사”는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.
        <br />
        ② 수신확인통지를 받은 이용자는 의사표시의 불일치등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 “회사”는 배송전에 이용자의 요청이 있는 경우에는 지체없이 그 요청에 따라 처리하여야 합니다.
        다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.
      </p>
      <h4>제5장 배송취소 / 환불</h4>
      <h5>제13조(재화등의 공급)</h5>
      <p>
        ① “회사”는 이용자와 재화등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다.
        다만, “회사”가 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다.
        이때 “회사”는 이용자가 재화등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
        <br />
        ② “회사”는 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다.
        만약 “회사”가 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다.
        다만 “회사”가 고의•과실이 없음을 입증한 경우에는 그러하지 아니합니다.
      </p>
      <h5>제14조(환급)</h5>
      <p>
        “회사”는 이용자가 구매신청한 재화등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
      </p>
      <h5>제15조(청약철회 등)</h5>
      <p>
        ① “MagicLine 포털”을 통하여 &quot;회사”와 재화등의 구매에 관한 계약을 체결한 이용자는 수신확인의 통지를 받은 날부터 7일 이내에는 청약의 철회를 할 수 있습니다.
        <br />
        ② 이용자는 재화등을 배송받은 경우 다음 각항의 “가”에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.
        <br />
        가. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)
        <br />
        나. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우
        <br />
        다. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히 감소한 경우
        <br />
        라. 같은 성능을 지닌 재화등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
        <br />
        ③ 제2항 “나”호 내지 “라”호의 경우에 “회사”가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.
        <br />
        ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화등의 내용이 표시•광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화등을 공급받은 날부터 3월이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
      </p>
      <h5>제16조(청약철회 등의 효과)</h5>
      <p>
        ① “회사”는 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미 지급받은 재화등의 대금을 환급합니다.
        <br />
        ② “회사”는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화등의 대금을 지급한 때에는 지체없이 당해 결제수단을 제공한 사업자로 하여금 재화등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
        <br />
        ③ 청약철회등의 경우 공급받은 재화등의 반환에 필요한 비용은 이용자가 부담합니다.
        “회사”는 이용자에게 청약철회등을 이유로 위약금 또는 손해배상을 청구하지 않습니다.
        다만 재화등의 내용이 표시•광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회등을 하는 경우 재화등의 반환에 필요한 비용은 “회사”가 부담합니다.
        <br />
        ④ 이용자가 재화등을 제공받을때 배송비를 부담한 경우에 “회사”는 청약철회시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 “MagicLine 포털”상에 명확하게 표시합니다.
      </p>
      <h4>제6장 이용자 의무사항</h4>
      <h5>제17조(개인정보보호)</h5>
      <p>
        ① “회사”는 이용자의 정보수집시 구매계약 이행에 필요한 최소한의 정보를 수집합니다.
        다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.
      </p>
      <p>
        가. 성명<br />
        나. 주소<br />
        다. 전화번호<br />
        라. 희망ID(회원의 경우)<br />
        마. 비밀번호(회원의 경우)<br />
        바. 전자우편주소(또는 이동전화번호)
      </p>
      ② “회사”가 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.
      <br />
      ③ 제공된 개인정보는 당해 이용자의 동의없이 목적외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 “회사”가 집니다.
      다만, 다음의 경우에는 예외로 합니다.
      <p>
        가. 배송업무상 배송업체에게 배송에 필요한 최소한의 이용자의 정보(성명, 주소, 전화번호)를 알려주는 경우
        <br />
        나. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
        <br />
        다. 재화등의 거래에 따른 대금정산을 위하여 필요한 경우
        <br />
        라. 도용방지를 위하여 본인확인에 필요한 경우
        <br />
        마. 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우
      </p>
      ④ “회사”가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 정보통신망이용촉진등에관한법률 제22조 내지 제25조에 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
      <br />
      ⑤ 이용자는 언제든지 “회사”가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “회사”는이에 대해 지체없이 필요한 조치를 취할 의무를 집니다.
      이용자가 오류의 정정을 요구한 경우에는 “회사”는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
      <br />
      ⑥ “회사”는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
      <br />
      ⑦ “회사” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체없이 파기합니다.
      <p></p>
      <h5>제18조(“회사”의 의무)</h5>
      <p>
        ① “회사”는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화•용역을 제공하는데 최선을 다하여야 합니다.
        <br />
        ② “회사”는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
        <br />
        ③ “회사”가 상품이나 용역에 대하여 「표시•광고의공정화에관한법률」 제3조 소정의 부당한 표시•광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
        <br />
        ④ “MagicLine 포털”은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.
      </p>
      <h5>제19조(회원의 ID 및 비밀번호에 대한 의무)</h5>
      <p>
        ① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
        <br />
        ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
        <br />
        ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “회사”에 통보하고 “회사”의 안내가 있는 경우에는 그에 따라야 합니다.
      </p>
      <h5>제20조(이용자의 의무)</h5>
      <p>
        이용자는 다음 행위를 하여서는 아니되며, 본 조를 포함한 본 약관 규정을 위반하여 “회사”에 손해를 끼친 경우 회사에 발생한 모든 손해를 배상하여야 합니다.
        이용자가 “MagicLine 포털”을 이용하면서 불법행위나 본 약관을 위반하는 행위를 하여 “회사”가 제3자로부터 손해배상청구 또는 소송을 비롯한 각종 이의제기를 받는다면 해당 이용자는 자신의 책임과 비용으로 “회사”를 면책시켜야 하며 “회사”가 면책되지 못하면 “회사”에 발생한 모든 손해를 배상하여야 합니다.
        <br />
        가. 신청 또는 변경시 허위 내용의 등록
        <br />
        나. 타인의 정보 도용
        <br />
        다. “MagicLine 포털”에 게시된 정보의 변경, 영리목적으로 도용 또는 “MagicLine 포털”에 게시된 가격정보의 외부 유포
        <br />
        라. “MagicLine 포털”이에서 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
        <br />
        마. “MagicLine 포털”상에서의 기타 제3자의 저작권 등 지적재산권에 대한 침해
        <br />
        바. “MagicLine 포털”상에서의 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
        <br />
        사. “MagicLine 포털”상에서 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위
        <br />
        아. “MagicLine 포털”과 관련한 이용자의 지위를 제3자에게 이전하는 행위
      </p>
      <h4>제7장 기타</h4>
      <h5>제21조(저작권의 귀속 및 면책사항)</h5>
      <p>
        ① “회사”가 “MagicLine 포털”상에 작성한 저작물에 대한 저작권 기타 지적재산권은 “회사”에 귀속합니다.
        <br />
        ② 이용자는 “MagicLine 포털”을 이용함으로써 얻은 정보 중 “회사”에게 지적재산권이 귀속된 정보를 “회사”의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
        <br />
        ③ “회사”는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.
        <br />
        ④ “회사”는 이용자가 “MagicLine 포털”을 이용하여 기대하는 수익을 달성하지 못했거나 상실한 것에 대해 책임을 지지 않으며 그 밖에 “MagicLine 포털”을 통하여 얻은 정보로 인하여 발생한 손해 등에 대해서도 책임을 지지 않습니다.
        회사는 이용자가 사이트에 게재한 정보, 자료, 사실의 신뢰도 및 정확성 등에 대해 책임을 지지 않습니다.
        <br />
        ⑤ “회사”는 “MagicLine 포털”을 매개로 이용자 상호간 또는 이용자와 제3자 상호 간에 분쟁이 발생하였을 때 이에 개입할 의무가 없으며 분쟁에 따른 손해를 배상할 책임도 없습니다.
        <br />
        ⑥ “회사”는 회원이 자신의 개인정보를 타인에게 유출하거나 제공하여 발생하는 피해에 대해 일절 책임을 지지 않습니다.
      </p>
      <h5>제22조(분쟁해결)</h5>
      <p>
        ① “회사”는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치•운영합니다.
        <br />
        ② “회사”는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다.
        다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
        <br />
        ③ “회사”와 이용자간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시•도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
      </p>
      <h5>제23조(재판권 및 준거법)</h5>
      <p>
        ① “회사”와 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다.
        다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
        <br />
        ② “회사”와 이용자간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
      </p>
      <h5>&nbsp;</h5>
      <h4>제8장 특별 규정</h4>
      <h5>제24조(특별규정)</h5>
      <p>당 약관에 명시되지 않은 사항은 전자거래기본법, 전자서명법, 전자상거래등 에서의소비자보호에관한 법률 및 기타 관련법령의 규정에 의합니다.</p>
      <p>※ 본 약관에 대한 저작권은 “회사”에 귀속하며 무단 복제, 배포, 전송 기타 저작권 침해행위를 엄금합니다</p>
      <h4>부칙</h4>
      <p>1. 이 약관은 2017 년 9월 20일부터 적용됩니다.</p>
      <p>
        “회사”는 이용자들의 개인정보보호를 매우 중요시하며, 이용자가 회사의 서비스를 이용함과 동시에 온라인상에서 회사에 제공한 개인정보가 보호 받을 수 있도록 최선을 다하고 있습니다.
        이에 “회사”는 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정 및 정보통신부가 제정한 개인정보보호지침을 준수하고 있습니다.
        “회사”는 개인정보 취급방침을 통하여 이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다.
      </p>
      <p>“회사”는 개인정보 취급방침을 “MagicLine 포털” 첫 화면에 공개함으로써 이용자들이 언제나 용이하게 보실 수 있도록 조치하고 있습니다.</p>
      <p>
        “회사”의 개인정보 취급방침은 정부의 법률 및 지침 변경이나 회사의 내부 방침 변경 등으로 인하여 수시로 변경될 수 있고, 이에 따른 개인정보 취급방침의 지속적인 개선을 위하여 필요한 절차를 정하고 있습니다.
        그리고 개인정보 취급방침을 개정하는 경우나 개인정보 취급방침 변경될 경우 “MagicLine 포털”의 첫페이지의 개인정보취급방침을 통해 고지하고 있습니다.
        이용자들께서는 “MagicLine 포털” 방문 시 수시로 확인하시기 바랍니다.
      </p>
    </div>
  </kendo-window>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { Window, getKendoWindow } from "@/utils/kendo/kendoWindow";

export default defineComponent({
  components: {
    "kendo-window": Window,
  },
  setup() {
    const kendoWindow = ref<any>();

    const onClick = (): void => {
      getKendoWindow(kendoWindow.value).center().open();
    };

    return { kendoWindow, onClick };
  },
});
</script>

<style scoped lang="scss">
@import "./TermsAndConditions.scss";
</style>