type Company = Readonly<{
  address: Address;
  businessRegNo: string;
  description: string;
  email: string;
  //familySites: FamilySites;
  name: string;
  primaryPhone: string;
  representative: string;
}>;

type Address = Readonly<{
  addressLine1: string;
  addressLine2?: string;
}>;

//type FamilySites = Readonly<{ [k in 'DENJOB' | 'DENALL' | 'DENPLE' | 'OSSTEM']: FamilySite }>;

// type FamilySite = Readonly<{
//   href: string;
//   text: string;
// }>;

const company: Company = {
  address: {
    addressLine1: '서울시 금천구 벚꽃로 298 대륭포스트타워 6차',
    addressLine2: '405 - 407호 (우편번호 08510)'
  },
  businessRegNo: '723-88-00552',
  description: 'Cozahn Co., Ltd ',
  email: 'support@magic-line.co.kr',
  // familySites: {
  //   DENJOB: { href: 'http://www.denjob.com', text: '덴잡' },
  //   DENALL: { href: 'http://www.denall.com', text: '덴올' },
  //   DENPLE: { href: 'http://www.denple.com', text: '덴플' },
  //   OSSTEM: { href: 'http://www.osstem.com', text: '오스템 임플란트' }
  // },
  name: '코잔주식회사',
  primaryPhone: '070-4394-5999',
  representative: '이정호'
};

export { company, Company };
