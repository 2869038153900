<template>
  <div class="footer">
    <div class="footer_menu_wrapper">
      <div class="footer_menu">
        <terms-and-conditions></terms-and-conditions>
        <privacy-policy></privacy-policy>
        <router-link :to="resources.SUPPORT.fullPath">{{ resources.SUPPORT.name }}</router-link>
      </div>
      <div class="family_site">
        <!-- <kendo-drop-down-list
          option-label="패밀리사이트"
          :data-source="Object.values(company.familySites)"
          data-text-field="text"
          @select="onSelect"
        ></kendo-drop-down-list> -->
      </div>
    </div>
    <hr class="footer_horizontal_rule" />
    <div class="footer_content_wrapper">
      <div class="footer_logo">
        <img src="@/assets/images/ci-cozann.png" alt="logo" />
      </div>
      <div class="footer_copy">
        <div class="address">
          {{ company.name }}
          <span class="dimmed">
            ( {{ company.description }} ) | 본사 : {{ company.address.addressLine1 }},
            {{ company.address.addressLine2 }} | 대표자 : {{ company.representative }} | 사업자등록번호 :
            {{ company.businessRegNo }}
            <br />
            통신판매업신고 : 제 2017-서울금천-0841호 | 고객센터/제품상담 :
            {{ company.primaryPhone }}
          </span>
          <br /><br />
          본 사이트에 표시된 각종 도안 및 문구 등은 국내외 특허 및 디자인, 상표법에 의해 보호받고 있습니다
          <br />
          따라서 이를 당사의 허락없이 사용, 배포, 게시 할 경우 민,형사 상의 책임을 질 수 있습니다.
        </div>
        <div class="address copy">COPYRIGHT © OSSTEM IMPLANT CO., LTD. ALL RIGHTS RESERVED.</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store/index';
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper';
import PrivacyPolicy from '@/components/footer/PrivacyPolicy.vue';
import TermsAndConditions from '@/components/footer/TermsAndConditions.vue';

export default defineComponent({
  components: {
    //'kendo-drop-down-list': DropDownList,
    PrivacyPolicy,
    TermsAndConditions
  },
  setup() {
    const store = useStore();

    const company = computed(() => store.state.company);

    const resources = computed(() => store.state.resources);

    const onSelect = (e: any): void => {
      if (e.dataItem.href) {
        window.open(e.dataItem.href, '_blank');
      }
    };

    return {
      company,
      resources,
      onSelect
    };
  }
});
</script>

<style scoped lang="scss">
@import './Footer.scss';
</style>
