import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useStore } from '@/store/index';

const store = useStore();

const { HOME, BRAND, ORTHODONTICS, DENTURES, PR, SUPPORT } = store.state.resources;

const routes: Array<RouteRecordRaw> = [
  {
    path: HOME.fullPath,
    component: () => import('@/views/MainView.vue')
  },
  {
    path: BRAND.subPath.INTRO.fullPath,
    component: () => import('@/views/brand/AboutMagicLineView.vue')
  },
  {
    path: BRAND.subPath.VALUE.fullPath,
    component: () => import('@/views/brand/MagicLineSolutionView.vue')
  },
  {
    path: BRAND.subPath.BUSINESS.fullPath,
    component: () => import('@/views/brand/WorkProcessView.vue')
  },
  {
    path: ORTHODONTICS.subPath.MAGIC_ALIGN.fullPath,
    component: () => import('@/views/orthodontic/MagicAlignView.vue')
  },
  {
    path: ORTHODONTICS.subPath.MAGIC_POSITIONER.fullPath,
    component: () => import('@/views/orthodontic/MagicPositionerView.vue')
  },
  // {
  //   path: ORTHODONTICS.subPath.MAGIC_RETAINER.fullPath,
  //   component: () => import('@/views/orthodontic/MagicRetainerView.vue')
  // },
  {
    path: DENTURES.subPath.MAGIC_4.fullPath,
    component: () => import('@/views/denture/Magic4View.vue')
  },
  {
    path: PR.subPath.LIBRARY.fullPath,
    component: () => import('@/views/pr/LibraryView.vue')
  },
  {
    path: `${PR.subPath.LIBRARY.fullPath}/:id`,
    props: route => ({
      id: Number(route.params.id)
    }),
    component: () => import('@/views/pr/LibraryDetailView.vue')
  },
  {
    path: SUPPORT.fullPath,
    component: () => import('@/views/SupportView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

export default router;
