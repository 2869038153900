<template>
  <ul class="sgnb">
    <local-navigation-bar-item
      v-for="(localNavigationBarItem, index) in localNavigationBar"
      :key="index"
      :title="localNavigationBarItem.title"
      :to="localNavigationBarItem.to"
    />
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LocalNavigationBarItem from './LocalNavigationBarItem.vue';

export default defineComponent({
  components: { LocalNavigationBarItem },
  props: {
    localNavigationBar: Object
  }
});
</script>

<style scoped lang="scss">
@import './LocalNavigationBar.scss';
</style>
