
import { defineComponent } from 'vue';
import { useStore } from '@/store/index';

export default defineComponent({
  setup() {
    const store = useStore();
    const { SUPPORT } = store.state.resources;

    return { SUPPORT };
  }
});
