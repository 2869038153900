type Exclude = Readonly<'subPath'>;

type Resources = Readonly<{
  HOME: Resource;
  BRAND: Omit<Resource, Exclude> & {
    subPath: {
      [k in 'INTRO' | 'VALUE' | 'BUSINESS']: Resource;
    };
  };
  ORTHODONTICS: Omit<Resource, Exclude> & {
    subPath: {
      // [k in 'MAGIC_ALIGN' | 'MAGIC_POSITIONER' | 'MAGIC_RETAINER']: Resource;
      [k in 'MAGIC_ALIGN' | 'MAGIC_POSITIONER']: Resource;
    };
  };
  DENTURES: Omit<Resource, Exclude> & {
    subPath: {
      MAGIC_4: Resource;
    };
  };
  PR: Omit<Resource, Exclude> & {
    subPath: {
      LIBRARY: Resource;
    };
  };
  SUPPORT: Resource;
}>;

type Resource = Readonly<{
  name: string;
  path: string;
  fullPath: string;
  subPath: any;
}>;

const resources: Resources = {
  HOME: {
    name: 'Home',
    path: '/',
    fullPath: '/',
    subPath: {}
  },
  BRAND: {
    name: 'MagicLine 소개',
    path: '/brand',
    fullPath: '/brand',
    subPath: {
      INTRO: {
        name: 'About MagicLine',
        path: '/intro',
        fullPath: '/brand/intro',
        subPath: {}
      },
      VALUE: {
        name: 'MagicLine Solution',
        path: '/value',
        fullPath: '/brand/value',
        subPath: {}
      },
      BUSINESS: {
        name: 'Work Process',
        path: '/business',
        fullPath: '/brand/business',
        subPath: {}
      }
    }
  },
  ORTHODONTICS: {
    name: '디지털교정',
    path: '/orthodontics',
    fullPath: '/orthodontics',
    subPath: {
      MAGIC_ALIGN: {
        name: 'MagicAlign',
        path: '/magic-align',
        fullPath: '/orthodontics/magic-align',
        subPath: {}
      },
      MAGIC_POSITIONER: {
        name: 'MAGIC Positioner',
        path: '/magic-positioner',
        fullPath: '/orthodontics/magic-positioner',
        subPath: {}
      }
      // MAGIC_RETAINER: {
      //   name: 'Magic Retainer',
      //   path: '/magic-retainer',
      //   fullPath: '/orthodontics/magic-retainer',
      //   subPath: {}
      // }
    }
  },
  DENTURES: {
    name: '임플란트 보철',
    path: '/dentures',
    fullPath: '/dentures',
    subPath: {
      MAGIC_4: {
        name: 'Magic4',
        path: '/magic-4',
        fullPath: '/dentures/magic-4',
        subPath: {}
      }
    }
  },
  PR: {
    name: '홍보센터',
    path: '/pr',
    fullPath: '/pr',
    subPath: {
      LIBRARY: {
        name: '영상자료',
        path: '/library',
        fullPath: '/pr/library',
        subPath: {}
      }
    }
  },
  SUPPORT: {
    name: '고객센터',
    path: '/support',
    fullPath: '/support',
    subPath: {}
  }
};

export { resources, Resources };
