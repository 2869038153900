import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/scss/base.scss';
import './assets/scss/common.scss';
import '@progress/kendo-ui/js/kendo.scrollview';
import '@progress/kendo-ui/js/kendo.dropdownlist';
import '@progress/kendo-ui/js/kendo.window';
import '@progress/kendo-theme-default/dist/all.css';

import { ScrollViewInstaller } from '@progress/kendo-scrollview-vue-wrapper';
import { DropdownsInstaller } from '@progress/kendo-dropdowns-vue-wrapper';
import { WindowInstaller } from '@progress/kendo-window-vue-wrapper';

import { initializeFacebookSDK } from '../src/api/facebook';

createApp(App)
  .use(ScrollViewInstaller)
  .use(DropdownsInstaller)
  .use(WindowInstaller)
  .use(initializeFacebookSDK)
  .use(store)
  .use(router)
  .mount('#app');
