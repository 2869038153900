<template>
  <li>
    <router-link :to="to">
      <span>{{ title }}</span>
    </router-link>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: String,
    to: String
  }
});
</script>

<style scoped lang="scss">
@import './LocalNavigationBarItem.scss';
</style>
