<template>
  <div class="top_menu_area_wrapper">
    <div class="top_menu_area">
      <div class="top_menu">
        <span class="i_login"></span>
        <a href="http://order.magic-line.co.kr/idp/login" target="_blank">로그인</a>
        <span class="line"></span>
        <a href="http://order.magic-line.co.kr/idp/login" target="_blank">회원가입</a>
        <span class="line"></span>
        <router-link :to="SUPPORT.fullPath">{{ SUPPORT.name }}</router-link>
        <span class="line"></span>
        <a href="http://order.magic-line.co.kr" target="_blank" class="font-blue">MY MagicLine</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store/index';

export default defineComponent({
  setup() {
    const store = useStore();
    const { SUPPORT } = store.state.resources;

    return { SUPPORT };
  }
});
</script>

<style scoped lang="scss">
@import './Service.scss';
</style>
