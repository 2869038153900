
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  props: {
    title: String,
    to: String
  },
  setup() {
    const route = useRoute();

    return { route };
  }
});
