<template>
  <layout-header></layout-header>
  <div class="page_container">
    <router-view />
  </div>
  <layout-footer></layout-footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Header, Footer } from './layout/index';

export default defineComponent({
  components: {
    'layout-header': Header,
    'layout-footer': Footer
  }
});
</script>

<style scoped lang="scss">
@import './App.scss';
</style>
