
import { defineComponent, ref } from 'vue';
import { useStore } from '@/store/index';
import GlobalNavigationBarItem from './GlobalNavigationBarItem.vue';
import LocalNavigationBar from './LocalNavigationBar.vue';

export default defineComponent({
  components: { GlobalNavigationBarItem, LocalNavigationBar },
  props: {
    globalNavigationBar: Object
  },
  setup() {
    const store = useStore();
    const show = ref<boolean>(false);

    const mouseenter = (): void => {
      show.value = true;
    };
    const mouseleave = (): void => {
      show.value = false;
    };

    return { store, show, mouseenter, mouseleave };
  }
});
