<template>
  <div class="gnb_area">
    <div class="gnb_wrapper">
      <div class="logo_wrapper">
        <p class="logo_text">디지털치과 토털 솔루션</p>
        <router-link :to="store.state.resources.HOME.fullPath">
          <img class="logo_img" src="@/assets/images/img-magicline-logo.png" alt="디지털 치과를 위한 MagicLINE"
        /></router-link>
      </div>
      <ul class="gnb" @mouseenter="mouseenter" @mouseleave="mouseleave">
        <global-navigation-bar-item
          v-for="(globalNavigationBarItem, index) in globalNavigationBar"
          :key="index"
          :title="globalNavigationBarItem.title"
          :to="globalNavigationBarItem.to"
        />
      </ul>
    </div>
    <!-- <transition name="sub"> -->
    <div class="sub_wrapper" @mouseenter="mouseenter" @mouseleave="mouseleave" v-if="show">
      <div class="sub">
        <local-navigation-bar
          v-for="(globalNavigationBarItem, index) in globalNavigationBar"
          :key="index"
          :localNavigationBar="globalNavigationBarItem.localNavigationBar"
        />
      </div>
    </div>
    <!-- </transition> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from '@/store/index';
import GlobalNavigationBarItem from './GlobalNavigationBarItem.vue';
import LocalNavigationBar from './LocalNavigationBar.vue';

export default defineComponent({
  components: { GlobalNavigationBarItem, LocalNavigationBar },
  props: {
    globalNavigationBar: Object
  },
  setup() {
    const store = useStore();
    const show = ref<boolean>(false);

    const mouseenter = (): void => {
      show.value = true;
    };
    const mouseleave = (): void => {
      show.value = false;
    };

    return { store, show, mouseenter, mouseleave };
  }
});
</script>

<style scoped lang="scss">
@import './GlobalNavigationBar.scss';
</style>
