
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store/index';
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper';
import PrivacyPolicy from '@/components/footer/PrivacyPolicy.vue';
import TermsAndConditions from '@/components/footer/TermsAndConditions.vue';

export default defineComponent({
  components: {
    //'kendo-drop-down-list': DropDownList,
    PrivacyPolicy,
    TermsAndConditions
  },
  setup() {
    const store = useStore();

    const company = computed(() => store.state.company);

    const resources = computed(() => store.state.resources);

    const onSelect = (e: any): void => {
      if (e.dataItem.href) {
        window.open(e.dataItem.href, '_blank');
      }
    };

    return {
      company,
      resources,
      onSelect
    };
  }
});
