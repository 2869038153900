
import { defineComponent } from 'vue';
import { Header, Footer } from './layout/index';

export default defineComponent({
  components: {
    'layout-header': Header,
    'layout-footer': Footer
  }
});
