
import { defineComponent, ref } from "vue";
import { Window, getKendoWindow } from "@/utils/kendo/kendoWindow";

export default defineComponent({
  components: {
    "kendo-window": Window,
  },
  setup() {
    const kendoWindow = ref<any>();

    const onClick = (): void => {
      getKendoWindow(kendoWindow.value).center().open();
    };

    return { kendoWindow, onClick };
  },
});
