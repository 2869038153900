<template>
  <li>
    <router-link :to="to" :class="{ on: route.path === to }">
      <span>{{ title }}</span>
    </router-link>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  props: {
    title: String,
    to: String
  },
  setup() {
    const route = useRoute();

    return { route };
  }
});
</script>

<style scoped lang="scss">
@import './GlobalNavigationBarItem.scss';
</style>
