const initializeFacebookSDK = (): Promise<unknown> => {
  return new Promise(() => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: process.env.VUE_APP_FACEBOOK_APP_ID,
        version: process.env.VUE_APP_FACEBOOK_SDK_VERSION,
        xfbml: true
      });
      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      const fjs: any = d.getElementsByTagName(s)[0];
      const js: any = d.createElement(s);

      if (d.getElementById(id)) return;

      js.id = id;
      js.src = '//connect.facebook.net/ko_KO/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
};

const shareFacebook = (): void => {
  FB.ui({
    method: 'share',
    display: 'popup',
    href: location.href
  });
};

export { initializeFacebookSDK, shareFacebook };
