
import { defineComponent, ref } from 'vue';
import { useStore } from '@/store/index';
import GlobalNavigationBar from '@/components/header/GlobalNavigationBar.vue';
import Service from '@/components/header/Service.vue';

type GlobalNavigationBarItem = {
  title: string;
  to: string;
  localNavigationBar: Array<LocalNavigationBarItem>;
};

type LocalNavigationBarItem = {
  title: string;
  to: string;
};

export default defineComponent({
  components: { GlobalNavigationBar, Service },
  setup() {
    const store = useStore();
    const { BRAND, ORTHODONTICS, DENTURES, PR } = store.state.resources;
    const globalNavigationBar = ref<Array<GlobalNavigationBarItem>>([
      {
        title: BRAND.name,
        to: BRAND.subPath.INTRO.fullPath,
        localNavigationBar: Object.values(BRAND.subPath).map(({ name, fullPath }) => ({ title: name, to: fullPath }))
      },
      {
        title: ORTHODONTICS.name,
        to: ORTHODONTICS.subPath.MAGIC_ALIGN.fullPath,
        localNavigationBar: Object.values(ORTHODONTICS.subPath).map(({ name, fullPath }) => ({
          title: name,
          to: fullPath
        }))
      },
      {
        title: DENTURES.name,
        to: DENTURES.subPath.MAGIC_4.fullPath,
        localNavigationBar: Object.values(DENTURES.subPath).map(({ name, fullPath }) => ({ title: name, to: fullPath }))
      },
      {
        title: PR.name,
        to: PR.subPath.LIBRARY.fullPath,
        localNavigationBar: Object.values(PR.subPath).map(({ name, fullPath }) => ({ title: name, to: fullPath }))
      }
    ]);

    return { store, globalNavigationBar };
  }
});
