
import { defineComponent } from 'vue';
import LocalNavigationBarItem from './LocalNavigationBarItem.vue';

export default defineComponent({
  components: { LocalNavigationBarItem },
  props: {
    localNavigationBar: Object
  }
});
